import React, { HTMLProps } from "react";
import css from "./Input.module.scss";

interface InputProps extends HTMLProps<HTMLInputElement> {
	className?: string;
	suffix?: React.ReactNode;
};

const Input: React.FC<InputProps> = ({ className, suffix, ...props }) => {
	return (
		<div className={`${css.inputWrapper} ${className && className}`}>
			<input type="text" {...props} className={css.input} />
			<span className={css.suffix}>{suffix}</span>
		</div>
	);
}

export default Input;