import { createSlice } from "@reduxjs/toolkit";

interface QueueItem {
  id: string;
  count: number;
}

interface InitialStateType {
  queue: QueueItem[]
};

const initialState: InitialStateType = {
  queue: []
};

const queueSlice = createSlice({
  name: "queueSlice",
  initialState,
  reducers: {
    addToQueue: (state, action) => {
      state.queue = state.queue.concat({ count: 1, id: action.payload });
    },
    removeFromQueue: (state, action) => {
      state.queue = [...state.queue.filter(queueItem => queueItem.id !== action.payload)];
    },
    incrementCount: (state, action) => {
      state.queue = [...state.queue.map(item => {
        if (item.id !== action.payload) return item;
        return { ...item, count: item.count + 1 }
      })]
    }
  }
});

export const { addToQueue, removeFromQueue, incrementCount } = queueSlice.actions;

export default queueSlice;