import axios, { AxiosResponse } from "axios";
import { BASE_URL } from "./backendApi";

export interface QuoteBalanceV2Request {
  address: string;
  spentTokens: {
    chain: string;
    address: string;
  }[];

  destination: {
    chain: string;
    minAmount: string;
    tokenAddress?: string;
  };
}

export interface QuoteBalanceV2Response {
  spentTokens: {
    chain: string;
    address: string;
    amount: string;
  }[];

  destination: {
    chain: string;
    fee: {
      amount: string;
      token: {
        symbol: string;
        decimals: number;
      };
    };
  };
}

export async function quoteBalanceV2(
  data: QuoteBalanceV2Request,
): Promise<QuoteBalanceV2Response> {
  try {
    const response: AxiosResponse<QuoteBalanceV2Response> = await axios.post(
      `${BASE_URL}/v2/balances/quote`,
      data,
    );
    return response.data;
  } catch (error) {
    console.error("Failed to get quote:", error);
    throw error;
  }
}
