import React, { useMemo, useState } from "react";
import { internalMigration, migrationBalanceMsg } from "../../api/backendApi";
import { useAccount, useSignMessage } from "wagmi";
import { AxiosError } from "axios";
import css from "./Forms.module.scss";
import Select from "../Select";
import Input from "../Input";
import { SubmitButton } from "../Button";
import { Token } from "../../constants";
import { notification } from "antd";
import { ModalProps } from "../Modal";
import { usePopupManager } from "react-popup-manager";
import { MigrateDescription } from "./Descriptions";
import { InfoIcon } from "../../assets";
import { addToQueue } from "../../features/queueSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useBackendBalances } from "../../hooks/useBackendBalances";

const InternalMigrateForm: React.FC<ModalProps> = ({ onClose }) => {
  const { address } = useAccount();
  const [token, setToken] = useState<Token>({} as Token);
  const [destinationAddress, setDestinationAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const popupManager = usePopupManager();

  const dispatch = useAppDispatch();
  const { address: stateAddress } = useAppSelector((state) => state.auth);

  const { balances, loading: balanceLoading } = useBackendBalances(
    address || null,
    null,
    stateAddress,
  );

  const { signMessageAsync } = useSignMessage();

  // const handleTokenChange = (value: Token) => {
  //   setToken(value);
  // };
  //
  // const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const inputValue = e.target.value;
  //
  //   const regex = /^[0-9]*\.?[0-9]*$/;
  //
  //   if (regex.test(inputValue)) {
  //     setAmount(inputValue);
  //     setError("");
  //   } else {
  //     if (inputValue.includes("-")) {
  //       setError("Invalid input. Negative numbers are not allowed");
  //     } else if (inputValue.includes(",")) {
  //       setError("Invalid input. Use . for decimal point");
  //     } else {
  //       setError("Invalid input. Only [0-9] and . are allowed");
  //     }
  //   }
  // };

  const handleDestinationAddressChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDestinationAddress(e.target.value);
  };

  // const handleChainChange = (value: string) => {
  //   setChain(parseInt(value));
  //   setToken({} as Token);
  // };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { message } = await migrationBalanceMsg({
        address: address!,
        recipient: destinationAddress,
      });
      const signature = await signMessageAsync({
        message,
      });

      const resp = await internalMigration({
        address: address!,
        recipient: destinationAddress,
        message,
        signature,
      });

      if (resp?.id) {
        dispatch(addToQueue(resp.id));
      }

      console.log("Internal transfer successful", resp);

      notification.success({ message: "Success!" });
      if (onClose) {
        onClose();
      }
    } catch (error) {
      console.error("Internal transfer failed:", error);
      if (error instanceof AxiosError) {
        return notification.error({ message: error.response?.data.message });
      }

      notification.error({ message: "Something went wrong." });
    } finally {
      setLoading(false);
    }
  };

  const showDescription = () => {
    popupManager.open(MigrateDescription);
  };

  // ToDo: check if user have balance on his account, if not rise error on bottom
  const availableBalance = React.useMemo(() => {
    const balance = Number(
      balances?.find((b) => b.tokenAddress === token.address)?.balance,
    );
    if (balance) {
      return balance / 10 ** token.decimals;
    }
    return null;
  }, [balances, balanceLoading, token]);

  return (
    <div>
      <h2 className={css.title}>
        Internal Migration{" "}
        <button type="button" onClick={showDescription}>
          <InfoIcon />
        </button>
      </h2>

      <form onSubmit={handleSubmit} className={css.form}>
        {/*<div className={`${css.twoCol} ${css.mb12}`}>*/}
        {/*  <Select*/}
        {/*    onChange={handleChainChange}*/}
        {/*    value={chain}*/}
        {/*    placeholder="Select Chain"*/}
        {/*    options={chainOptions}*/}
        {/*  />*/}
        {/*  <Select*/}
        {/*    onChange={handleTokenChange}*/}
        {/*    value={token}*/}
        {/*    placeholder="Select Token"*/}
        {/*    options={tokenOptions}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<Input*/}
        {/*  value={amount}*/}
        {/*  onChange={handleAmountChange}*/}
        {/*  placeholder="Enter amount"*/}
        {/*  suffix={token.symbol}*/}
        {/*  className={css.mb12}*/}
        {/*/>*/}
        <Input
          value={destinationAddress}
          onChange={handleDestinationAddressChange}
          placeholder="Enter destination address"
          className={css.mb12}
        />
        {balanceLoading && (
          <p className={`${css.mb12} ${css.subtext}`}>Loading...</p>
        )}

        {!balanceLoading &&
          (availableBalance ? (
            <p className={`${css.mb12} ${css.subtext}`}>
              Available {availableBalance} {token.symbol}
            </p>
          ) : (
            <p className={`${css.mb12} ${css.subtext}`}>Insufficient balance</p>
          ))}
        {error && <p className={`${css.errorMessage} ${css.mb12}`}>{error}</p>}
        <div className={css.submitButtonContainer}>
          <SubmitButton disabled={loading || !destinationAddress}>
            {loading ? "Loading..." : "Migrate"}
          </SubmitButton>
        </div>
      </form>
    </div>
  );
};

export default InternalMigrateForm;
