import axios, { AxiosResponse } from "axios";
import { BASE_URL } from "./backendApi";
import { BalanceUpdateQueueResponse } from "./base";

export interface ConsumeBalanceV2Request {
  address: string;

  spentTokens: {
    chain: string;
    address: string;
  }[];

  destination: {
    chain: string;
    address: string;
    minAmount: string;
    tokenAddress?: string;
  };

  // authorization
  message: string;
  signature: string;
}

export async function consumeBalanceV2(
  data: ConsumeBalanceV2Request,
): Promise<BalanceUpdateQueueResponse> {
  try {
    const response: AxiosResponse<BalanceUpdateQueueResponse> =
      await axios.post(`${BASE_URL}/v2/balances/consume`, data);
    return response.data;
  } catch (error) {
    console.error("Failed to consume backend balance:", error);
    throw error;
  }
}
