import React from "react";
import Modal, { ModalProps } from "../../Modal";
import css from "./Descriptions.module.scss";

const WithdrawDescription: React.FC<ModalProps> = (props) => {
  return (
    <Modal {...props}>
      <div className={css.content}>
        <h3>Withdraw Form Description:</h3>
        <br />
        <p>This form allows users to withdraw tokens from their account to a specified recipient and blockchain network. Follow the guide below to use this form:</p>
        <br />
        <ol className={css.list}>
          <li><b>Token Address:</b> Enter the ERC-20 token address you intend to withdraw. Ensure the token address matches the selected blockchain network.</li>
          <li><b>Amount:</b> Input the quantity of tokens you'd like to withdraw. Ensure you have the required balance in your account.</li>
          <li><b>Recipient:</b> Enter the address that will receive the withdrawn tokens. Always double-check the recipient's address for accuracy.</li>
          <li><b>Chain:</b> Choose the blockchain network for the withdrawal. Ensure the selected network supports the specified token.</li>
          <li>Press the <b>Withdraw</b> button to initiate the process. You might be asked to sign a message confirming the withdrawal details. Once confirmed, the transaction will be processed.</li>
        </ol>
        <br />
        <p>Always review all details thoroughly before confirming any transaction.</p>
      </div>
    </Modal>
  );
}

export default WithdrawDescription;