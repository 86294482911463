// src/hooks/useBackendBalances.ts
import { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../api/backendApi";
import { LOGOUT_MESSAGES, LS_AUTH_KEY } from "../util/constants";
import { useAppDispatch } from "../app/hooks";
import { logout } from "../features/authSlice";

export interface BackendBalances {
  id: string;
  address: string;
  tokenAddress: string;
  balance: string;
  chain: string;
}

export function useBackendBalances(
  address: string | null,
  token: string | null,
  stateAddress: string | null | undefined,
) {
  const [balances, setBalances] = useState<BackendBalances[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const AUTH_TOKEN = window.localStorage.getItem(LS_AUTH_KEY);
  const auth = AUTH_TOKEN && JSON.parse(AUTH_TOKEN);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchBalances = async (e?: any) => {
      if (!AUTH_TOKEN) {
        dispatch(logout());
        return;
      }

      if (address) {
        setLoading(true);
        setError(null);
        try {
          const response = await axios.get(`${BASE_URL}/balances/${address}`, {
            headers: { Authorization: `Bearer ${auth.token}` },
          });

          setBalances(response.data);

          if (e) {
            e.detail();
          }
        } catch (error: any) {
          console.error("Failed to fetch backend balances:", error);
          setError(error.message);

          if (LOGOUT_MESSAGES.includes(error?.response?.data?.message)) {
            dispatch(logout());
          }
        } finally {
          setLoading(false);
        }
      } else {
        setBalances([]);
      }
    };
    let intervalId: NodeJS.Timer;

    if (AUTH_TOKEN) {
      fetchBalances();

      intervalId = setInterval(fetchBalances, 10000); // fetch every 10 seconds
      window.addEventListener("update-gas-balances", fetchBalances);
    }

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("update-gas-balances", fetchBalances);
    };
  }, [address, token, stateAddress]);

  return { balances, loading, error };
}
