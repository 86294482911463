import React, { useMemo, useState } from "react";
import {
  consumeBalanceV2,
  ConsumeBalanceV2Request,
  quoteBalanceV2,
  QuoteBalanceV2Request,
} from "../../api";
import { useAccount, useSignMessage } from "wagmi";
import { usePopupManager } from "react-popup-manager";
import { AxiosError } from "axios";
import css from "./Forms.module.scss";
import Select from "../Select";
import Input from "../Input";
import { SubmitButton } from "../Button";
import Modal from "../Modal";
import { CHAIN, ChainInfo, ChainKey, Token } from "../../constants";
import { notification } from "antd";
import { InfoIcon } from "../../assets";
import { ConsumeV2Description } from "./Descriptions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addToQueue } from "../../features/queueSlice";
import { MultiSelect } from "react-multi-select-component";
import { useBackendBalances } from "../../hooks/useBackendBalances";
import ConfirmSendGasModal from "../ConfirmSendGasModal";

interface DepositFormProps {
  isOpen?: boolean;
  onClose?: () => void;
}

const ConsumeV2Form: React.FC<DepositFormProps> = (props) => {
  const { address } = useAccount();
  const tokens = useAppSelector((state) => state.tokens.tokens);

  const [chain, setChain] = useState<number>(1); // New state
  const [selectedTokens, setSelectedTokens] = useState<
    {
      label: string;
      value: {
        chain: string;
        address: string;
      };
    }[]
  >([]);
  const [destinationChainInfo, setDestinationChainInfo] = useState<ChainInfo>(
    tokens[CHAIN.Ethereum as string] as ChainInfo,
  );
  const [destinationToken, setDestinationToken] = useState<Token>({} as Token);
  const [minDestinationAmount, setMinDestinationAmount] = useState("");
  const [destinationAddress, setDestinationAddress] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { signMessageAsync } = useSignMessage();
  const popupManager = usePopupManager();

  const dispatch = useAppDispatch();
  const { address: stateAddress } = useAppSelector((state) => state.auth);
  const { balances, loading: balanceLoading } = useBackendBalances(
    address || null,
    null,
    stateAddress,
  );

  const availableBalanceOptions = React.useMemo(() => {
    if (balanceLoading) {
      return [];
    }
    return balances.map((b) => {
      const token = tokens[b.chain].tokens.find(
        (t) => t.address === b.tokenAddress,
      );

      return {
        label: `${b.chain}.${token?.symbol || b.tokenAddress}`,
        value: {
          chain: b.chain,
          address: b.tokenAddress,
        },
      };
    });
  }, [balances, balanceLoading]);

  const chainOptions = useMemo(
    () =>
      Object.values(tokens)
        .filter(
          (chainInfo) =>
            chainInfo.native.actions.includes("consume") ||
            chainInfo.tokens.some((t) =>
              ["withdraw", "consume"].some((a) => t.actions.includes(a)),
            ),
        )
        .map((chainInfo) => ({
          label: chainInfo.name,
          value: chainInfo.key,
        })),
    [tokens],
  );

  const tokenOptions = useMemo(() => {
    const options = destinationChainInfo.tokens
      .filter((token) =>
        ["withdraw", "consume"].some((a) => token.actions.includes(a)),
      )
      .map((token) => ({
        label: token.symbol,
        value: { ...token, key: token.address },
      }));

    return (
      options.concat([
        {
          label: destinationChainInfo.native.symbol,
          value: { ...destinationChainInfo.native, address: "", key: "" },
        },
      ]) || []
    );
  }, [destinationChainInfo, tokens]);

  const handleDestinationChainChange = (key: ChainKey) => {
    setDestinationChainInfo(tokens[key as string] as ChainInfo);
    setDestinationToken({} as Token);
  };

  const handleDestinationTokenChange = (value: Token) => {
    setDestinationToken(value);
  };

  const handleMinDestinationAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = e.target.value;

    const regex = /^[0-9]*\.?[0-9]*$/;

    if (regex.test(inputValue)) {
      setMinDestinationAmount(inputValue);
      setError("");
    } else {
      if (inputValue.includes("-")) {
        setError("Invalid input. Negative numbers are not allowed");
      } else if (inputValue.includes(",")) {
        setError("Invalid input. Use . for decimal point");
      } else {
        setError("Invalid input. Only [0-9] and . are allowed");
      }
    }
  };

  const handleDestinationAddressChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDestinationAddress(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const minDestinationAmountStr = Math.round(
        Number(minDestinationAmount) *
          10 **
            (destinationToken?.decimals ||
              destinationChainInfo.native.decimals),
      ).toLocaleString("fullwide", { useGrouping: false });

      const timestamp = Date.now();
      const message = `Consume request for ${address} with minDestinationAmount ${minDestinationAmountStr} to ${destinationChainInfo?.key} chain at ${timestamp}`;
      const signature = await signMessageAsync({
        message,
      });
      // Call the consume function from your backendApi here
      // Replace this with the appropriate parameters for your demo
      const data: ConsumeBalanceV2Request = {
        address: String(address!),
        spentTokens: selectedTokens.map((t) => t.value),
        destination: {
          chain: destinationChainInfo.key,
          address: destinationAddress,
          minAmount: minDestinationAmountStr,
          ...(destinationToken?.address
            ? { tokenAddress: destinationToken?.address }
            : {}),
        },
        message,
        signature,
      };
      const resp = await consumeBalanceV2(data);
      console.log("Consume successful", resp);
      if (resp?.id) {
        dispatch(addToQueue(resp.id));
      }

      notification.success({ message: "Success!" });

      if (props.onClose) {
        props.onClose();
      }
    } catch (error) {
      console.error("Consume failed:", error);

      if (error instanceof AxiosError) {
        return notification.error({
          message: JSON.stringify(error?.response?.data.message),
        });
      }

      notification.error({ message: "Something went wrong." });
    } finally {
      setLoading(false);
    }
  };

  const handleGetQuoteSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const minDestinationAmountStr = Math.round(
        Number(minDestinationAmount) *
          10 **
            (destinationToken?.decimals ||
              destinationChainInfo.native.decimals),
      ).toLocaleString("fullwide", { useGrouping: false });

      const data: QuoteBalanceV2Request = {
        address: String(address!),
        spentTokens: selectedTokens.map((t) => t.value),
        destination: {
          chain: destinationChainInfo.key,
          minAmount: minDestinationAmountStr,
          ...(destinationToken?.address
            ? { tokenAddress: destinationToken?.address }
            : {}),
        },
      };

      const resp = await quoteBalanceV2(data);

      console.log("Quote successful", resp);

      popupManager.open(ConfirmSendGasModal, {
        onClose: () => {},
        spentAssets: resp.spentTokens.map((item) => ({
          chain: item.chain,
          tokenAddress: item.address,
          amount: item.amount,
        })),
        onSuccess: handleSubmit,
        chainId: destinationChainInfo.chainId,
        amount: +minDestinationAmount,
        token: destinationToken
          ? destinationToken.symbol
          : destinationChainInfo.native.symbol,
      });
    } catch (error) {
      console.error("Quote failed:", error);

      if (error instanceof AxiosError) {
        return notification.error({
          message: JSON.stringify(error?.response?.data.message),
        });
      }

      notification.error({ message: "Something went wrong." });
    } finally {
      setLoading(false);
    }
  };

  const showDescription = () => {
    popupManager.open(ConsumeV2Description);
  };

  return (
    <Modal {...props}>
      <div className={css.formWrapper}>
        <h2 className={css.title}>
          Consume V2 (Included Withdraw)
          <button type="button" onClick={showDescription}>
            <InfoIcon />
          </button>
        </h2>
        <form onSubmit={handleGetQuoteSubmit} className={css.form}>
          <MultiSelect
            className={`${css.dark} ${css.mb12}`}
            options={availableBalanceOptions}
            value={selectedTokens}
            onChange={setSelectedTokens}
            labelledBy="Select Balance"
          />
          <div className={`${css.twoCol} ${css.mb12}`}>
            <Select
              onChange={handleDestinationChainChange}
              value={destinationChainInfo?.key}
              placeholder="Select Dest Chain"
              options={chainOptions}
            />
            <Select
              onChange={handleDestinationTokenChange}
              value={destinationToken}
              disabled={!destinationChainInfo}
              placeholder="Select Token"
              options={tokenOptions}
            />
          </div>
          <Input
            value={minDestinationAmount}
            onChange={handleMinDestinationAmountChange}
            placeholder="Min destination amount"
            suffix={
              destinationToken
                ? destinationToken.symbol
                : destinationChainInfo.native.symbol
            }
            className={css.mb12}
          />
          <Input
            value={destinationAddress}
            onChange={handleDestinationAddressChange}
            placeholder="Enter destination address"
            className={css.mb12}
          />
          {error && (
            <p className={`${css.errorMessage} ${css.mb12}`}>{error}</p>
          )}
          <div className={css.submitButtonContainer}>
            <SubmitButton
              disabled={
                loading ||
                !minDestinationAmount ||
                !destinationAddress ||
                !destinationChainInfo
              }
            >
              {loading ? "Loading..." : "Consume"}
            </SubmitButton>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ConsumeV2Form;
