import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LS_AUTH_KEY } from "../util/constants";

interface InitialStateType {
    address?: string | null;
    token?: string | null;
    loginLoading: boolean;
    connectLoading: boolean;
};

const initialState: InitialStateType = {
    address: "",
    token: "",
    loginLoading: false,
    connectLoading: false
};

const authSlice = createSlice({
    name: "authSlice",
    initialState,
    reducers: {
        setAuthData: (state, action: PayloadAction<{ address: string, token: string }>) => {
            state.address = action.payload.address;
            state.token = action.payload.token;
        },
        logout: (state) => {
            state.address = null;
            state.token = null;
            localStorage.removeItem(LS_AUTH_KEY);
        },
        setLoginLoading: (state, action) => {
            state.loginLoading = action.payload;
        },
        setConnectLoading: (state, action) => {
            state.connectLoading = action.payload;
        },
    }
});

export const { setAuthData, logout, setConnectLoading, setLoginLoading } = authSlice.actions;

export default authSlice;