import React, { useMemo, useState } from "react";
import {
  ConsumeBalanceRequest,
  QuoteBalanceRequest,
  consumeBalance,
  quoteBalance,
} from "../../api/backendApi";
import { useAccount, useSignMessage } from "wagmi";
import { AxiosError } from "axios";
import css from "./Forms.module.scss";
import Input from "../Input";
import Select from "../Select";
import { SubmitButton } from "../Button";
import { ChainInfo, ChainKey, CHAIN } from "../../constants";
import { usePopupManager } from "react-popup-manager";
import ConfirmSendGasModal from "../ConfirmSendGasModal";
import { notification } from "antd";
import { SendGasDescription } from "./Descriptions";
import { InfoIcon } from "../../assets";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addToQueue } from "../../features/queueSlice";

const ConsumeForm: React.FC = () => {
  const { address } = useAccount();
  const [minDestinationAmount, setMinDestinationAmount] = useState("");
  const [destinationAddress, setDestinationAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { signMessageAsync } = useSignMessage();

  const popupManager = usePopupManager();

  const dispatch = useAppDispatch();

  const { tokens } = useAppSelector((state) => state.tokens);

  const [destinationChainInfo, setDestinationChainInfo] = useState<ChainInfo>(
    tokens[CHAIN.Ethereum as string] as ChainInfo,
  );

  const chainOptions = useMemo(
    () =>
      Object.entries(tokens)
        .filter(([key, chainInfo]) =>
          chainInfo.native.actions.includes("consume"),
        )
        .map(([key, chainInfo]) => ({
          label: chainInfo.name,
          value: key,
        })),
    [tokens],
  );

  const handleMinDestinationAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = e.target.value;

    const regex = /^[0-9]*\.?[0-9]*$/;

    if (regex.test(inputValue)) {
      setMinDestinationAmount(inputValue);
      setError("");
    } else {
      if (inputValue.includes("-")) {
        setError("Invalid input. Negative numbers are not allowed");
      } else if (inputValue.includes(",")) {
        setError("Invalid input. Use . for decimal point");
      } else {
        setError("Invalid input. Only [0-9] and . are allowed");
      }
    }
  };

  const handleDestinationAddressChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDestinationAddress(e.target.value);
  };

  const handleDestinationChainChange = (key: ChainKey) => {
    setDestinationChainInfo(tokens[key as string] as ChainInfo);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const minDestinationAmountStr = Math.round(
        Number(minDestinationAmount) *
          10 ** destinationChainInfo.native.decimals,
      ).toLocaleString("fullwide", { useGrouping: false });

      const timestamp = Date.now();
      const message = `Consume request for ${address} with minDestinationAmount ${minDestinationAmountStr} to ${destinationChainInfo?.key} chain at ${timestamp}`;
      const signature = await signMessageAsync({
        message,
      });
      // Call the consume function from your backendApi here
      // Replace this with the appropriate parameters for your demo
      if (destinationChainInfo) {
        const data: ConsumeBalanceRequest = {
          address: String(address!),
          destinationAddress,
          destinationChain: destinationChainInfo.key,
          minDestinationAmount: minDestinationAmountStr,
          message,
          signature,
        };
        const resp = await consumeBalance(data);
        console.log("Consume successful", resp);
        if (resp?.id) {
          dispatch(addToQueue(resp.id));
        }

        notification.success({ message: "Success!" });
      }
    } catch (error) {
      console.error("Consume failed:", error);

      if (error instanceof AxiosError) {
        return notification.error({ message: error?.response?.data.message });
      }

      notification.error({ message: "Something went wrong." });
    } finally {
      setLoading(false);
    }
  };

  const handleGetQuoteSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const minDestinationAmountStr = Math.round(
        Number(minDestinationAmount) *
          10 ** destinationChainInfo.native.decimals,
      ).toLocaleString("fullwide", { useGrouping: false });

      if (destinationChainInfo) {
        const data: QuoteBalanceRequest = {
          address: String(address!),
          destinationChain: destinationChainInfo.key,
          minDestinationAmount: minDestinationAmountStr,
        };

        const resp = await quoteBalance(data);

        console.log("Quote successful", resp);

        popupManager.open(ConfirmSendGasModal, {
          onClose: () => {},
          spentAssets: resp.spentAssets,
          onSuccess: handleSubmit,
          chainId: destinationChainInfo.chainId,
          amount: +minDestinationAmount,
          token: destinationChainInfo.native.symbol,
        });
      }
    } catch (error) {
      console.error("Quote failed:", error);

      if (error instanceof AxiosError) {
        return notification.error({ message: error?.response?.data.message });
      }

      notification.error({ message: "Something went wrong." });
    } finally {
      setLoading(false);
    }
  };

  const showDescription = () => {
    popupManager.open(SendGasDescription);
  };

  return (
    <div>
      <h2 className={css.title}>
        Send Gas{" "}
        <button type="button" onClick={showDescription}>
          <InfoIcon />
        </button>
      </h2>

      <form
        onSubmit={handleGetQuoteSubmit}
        className={`${css.form} ${css.w60}`}
      >
        <Select
          onChange={handleDestinationChainChange}
          value={destinationChainInfo?.key}
          placeholder="Select Destination Chain"
          options={chainOptions}
          className={css.mb12}
        />
        <Input
          value={minDestinationAmount}
          onChange={handleMinDestinationAmountChange}
          placeholder="Min destination amount"
          suffix={
            destinationChainInfo?.key
              ? destinationChainInfo.native.symbol
              : null
          }
          className={css.mb12}
        />
        <Input
          value={destinationAddress}
          onChange={handleDestinationAddressChange}
          placeholder="Enter destination address"
          className={css.mb12}
        />
        {error && <p className={`${css.errorMessage} ${css.mb12}`}>{error}</p>}
        <div className={css.submitButtonContainer}>
          <SubmitButton
            disabled={
              loading ||
              !minDestinationAmount ||
              !destinationAddress ||
              !destinationChainInfo
            }
          >
            {loading ? "Loading..." : "Send Gas"}
          </SubmitButton>
        </div>
      </form>
    </div>
  );
};

export default ConsumeForm;
