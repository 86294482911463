import React from "react";
import { useAccount, useNetwork, useSignMessage } from "wagmi";
import { getAuthMessage, login } from "../api/backendApi";
import Button from "./Button";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setLoginLoading } from "../features/authSlice";
import { useBreakpoint } from "../hooks/useBreakpoints";

type SignInProps = {
  onSuccess: (args: { address: string; token: string }) => void;
  onError: (args: { error: Error }) => void;
  className?: string;
};

function SignIn({ onSuccess, onError, className }: SignInProps) {
  const [state, setState] = React.useState<{
    loading?: boolean;
    nonce?: string;
  }>({});

  const { address } = useAccount();
  const { chain } = useNetwork();

  const { signMessageAsync } = useSignMessage();
  const dispatch = useAppDispatch();
  const { loginLoading } = useAppSelector((state) => state.auth);

  const { isMobile } = useBreakpoint();

  const signIn = async () => {
    try {
      setState({ ...state, loading: true });
      dispatch(setLoginLoading(true));

      const chainId = chain?.id;
      const { nonce, message } = await getAuthMessage([address as string]);

      if (!address || !chainId || !nonce) return;

      setState((x) => ({ ...x, loading: true }));

      const signature = await signMessageAsync({
        message: message,
      });

      // Verify signature
      const verifyRes = await login(address, signature);
      setState((x) => ({ ...x, loading: false }));
      onSuccess({ address, token: verifyRes.access });
    } catch (error) {
      setState((x) => ({ ...x, loading: false }));
      onError({ error: error as Error });
    } finally {
      setState({ ...state, loading: false });
      dispatch(setLoginLoading(false));
    }
  };

  return (
    <Button
      disabled={state.loading}
      onClick={signIn}
      className={className || undefined}
    >
      {loginLoading
        ? "Signing in..."
        : !isMobile
          ? "Sign-In with Wallet"
          : "Sign in"}
    </Button>
  );
}

export default SignIn;
