import { useLayoutEffect, useState } from "react";

export const breakpoints = {
  'mobile': 612,
  'tablet': 870,
};

export const useBreakpoint = () => {
  const [isTablet, setIsTablet] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  function handleResize() {
    if (window.innerWidth <= breakpoints.mobile) {
      setIsMobile(true);
      setIsTablet(false);
      setIsDesktop(false);
    } else if (window.innerWidth <= breakpoints.tablet) {
      setIsMobile(false);
      setIsTablet(true);
      setIsDesktop(false);
    } else {
      setIsMobile(false);
      setIsTablet(false);
      setIsDesktop(true);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { isDesktop, isMobile, isTablet };
};
