import { createSlice } from "@reduxjs/toolkit";

type ChainTokens = {
  [key: string]: {
    chainId: number,
    key: string,
    name: string,
    native: { symbol: string, decimals: number, actions: string[] }
    tokens: {
      symbol: string,
      decimals: number,
      address: string,
      actions: string[]
    }[]
  }
}

interface InitialStateType {
  tokens: ChainTokens;
  tokensLoading: boolean;
}

const initialState: InitialStateType = {
  tokens: {},
  tokensLoading: false,
};

const tokensSlice = createSlice({
  name: "tokensSlice",
  initialState,
  reducers: {
    setTokens: (state, action) => {
      state.tokens = action.payload;
    },
    setTokensLoading: (state, action) => {
      state.tokensLoading = action.payload;
    },
  },
});

export const { setTokens, setTokensLoading } = tokensSlice.actions;

export default tokensSlice;
