import React, { useState } from 'react';
import css from './CopyToClipboard.module.scss';

interface CopyToClipboardProps {
  text: string;
  children?: React.ReactNode;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(text);
    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 1000);
  };

  return (
    <span className={css.address} onClick={handleCopy}>
      {children}
      {showTooltip && <span className={css.copyTooltip}>Copied!</span>}
    </span>
  );
};

export default CopyToClipboard;