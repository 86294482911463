import { Global, css } from "@emotion/react";

const globalStyles = css`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

  html {
    background-color: #313137;
  }

  body {
    background-color: transparent;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Inter;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
`;

const GlobalStyles = () => {
  return (
    <div>
      <Global styles={globalStyles} />
    </div>
  );
};

export default GlobalStyles;
