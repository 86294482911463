import React from "react";
import Modal, { ModalProps } from "../Modal";
import css from "./StatusModal.module.scss";

interface StatusModalProps extends ModalProps {
  json?: string;
  isTimeout?: boolean;
}

const StatusModal: React.FC<StatusModalProps> = (props) => {
  return (
    <Modal {...props}>
      <div className={css.wrapper}>
        <h2>{props.isTimeout ? 'Timeout': 'Transaction Results'}</h2>
        <br />
        <p>{props.json}</p>
      </div>
    </Modal>
  );
}

export default StatusModal;