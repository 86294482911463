import React, { useEffect, useMemo } from "react";
import DepositForm from "../Forms/DepositForm";
import ConsumeForm from "../Forms/ConsumeForm";
import ConsumeV2Form from "../Forms/ConsumeV2Form";
import Header from "../Header";
import { useEthereumBalances } from "../../hooks/useEthereumBalances";
import { useBackendBalances } from "../../hooks/useBackendBalances";
import { useAccount, useChainId, useNetwork, useProvider } from "wagmi";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setAuthData } from "../../features/authSlice";
import { LS_AUTH_KEY } from "../../util/constants";
import Table from "../Table";
import css from "./Home.module.scss";
import { shortenAddress } from "../../util/helpers";
import { RefreshIcon } from "../../assets";
import { useBreakpoint } from "../../hooks/useBreakpoints";
import { usePopupManager } from "react-popup-manager";
import TransferModal from "../TransferModal";
import { useTransactionHistory } from "../../hooks/useTransactionHistory";
import { getAllowedTokens, getBalanceQueueUpdate } from "../../api/backendApi";
import { setTokens } from "../../features/tokensSlice";
import { incrementCount, removeFromQueue } from "../../features/queueSlice";
import StatusModal from "../StatusModal";
import CopyToClipboard from "../CopyToClipboard";
import { Token } from "../../constants";

type TableTabType = "on-chain" | "gas-tank" | "history";

const Home: React.FC = () => {
  const [state, setState] = React.useState<{
    address?: string;
    token?: string;
    error?: Error;
    loading?: boolean;
  }>({});
  const [currentTableTab, setCurrentTableTab] =
    React.useState<TableTabType>("on-chain");

  const [onChainLoading, setOnChainLoading] = React.useState(false);
  const [gasTankLoading, setGasTankLoading] = React.useState(false);

  const { address: stateAddress, token } = useAppSelector(
    (state) => state.auth,
  );

  const dispatch = useAppDispatch();
  const { queue } = useAppSelector((state) => state.queue);
  const { isDesktop } = useBreakpoint();

  const popupManager = usePopupManager();

  const intervalRef = React.useRef<NodeJS.Timer>();

  useEffect(() => {
    // Access token is stored in localstorage
    const AUTH_TOKEN = window.localStorage.getItem(LS_AUTH_KEY);
    const auth = AUTH_TOKEN && JSON.parse(AUTH_TOKEN);
    setState((x) => ({ ...x, ...auth }));
    if (auth) {
      dispatch(setAuthData({ address: auth.address, token: auth.token }));

      getAllowedTokens(auth.token).then((tokens) => {
        dispatch(setTokens(tokens));
      });
    }
  }, [stateAddress, token]);

  const { address } = useAccount();

  const { tokens } = useAppSelector((state) => state.tokens);

  // const { connect } = useConnect();
  const provider = useProvider();
  const chainId = useChainId();
  const { chain } = useNetwork();

  const chainInfo = useMemo(
    () => Object.values(tokens).find((token) => token.chainId == chainId),
    [tokens, chainId],
  );

  const ethereumBalances = useEthereumBalances(
    provider as any,
    address || null,
    chainInfo?.tokens.map((t) => t.address) || [],
  );

  const { balances: backendBalances } = useBackendBalances(
    address || null,
    state.token || null,
    stateAddress,
  );

  const {
    history: transactionHistory,
    loading: transactionHistoryLoading,
    setLoading: setTransactionHistoryLoading,
  } = useTransactionHistory(address || null, state.token || null, stateAddress);

  const OnChainBalances = useMemo(() => {
    return (
      <div className={css.tableContainer}>
        <Table
          columnLabels={["token address", "token", "amount"]}
          rows={Object.keys(ethereumBalances.tokenBalances)
            .filter((b) => chainInfo?.tokens.find((t) => t.address === b))
            .map((k) => {
              const token = chainInfo?.tokens.find(
                (t) => t.address === k,
              ) as Token;
              return [
                <CopyToClipboard text={k}>{shortenAddress(k)}</CopyToClipboard>,
                token.symbol,
                <span style={{ fontSize: 12, letterSpacing: 0.6 }}>
                  {Number(
                    (
                      Number(ethereumBalances.tokenBalances[k]) /
                      10 ** token.decimals
                    ).toString(),
                  ).toLocaleString()}
                </span>,
              ];
            })}
          loading={onChainLoading}
          placeholderText="Sign in to view balances"
          showPlaceholder={!Boolean(address)}
          copyValue={"token address"}
        />
      </div>
    );
  }, [ethereumBalances, onChainLoading, address]);

  const GasTankBalances = useMemo(() => {
    return (
      <div className={`${css.tableContainer} ${css.tableWide}`}>
        <Table
          columnLabels={["chain", "token address", "token", "amount", ""]}
          rows={backendBalances
            .filter((b) =>
              Object.entries(tokens)
                .find(([key, value]) => key === b.chain)?.[1]
                .tokens.find((t) => t.address === b.tokenAddress.toLowerCase()),
            )
            .map((b) => {
              const token = Object.entries(tokens)
                .find(([key, value]) => key === b.chain)?.[1]
                .tokens.find(
                  (t) => t.address === b.tokenAddress.toLowerCase(),
                ) as Token;

              return [
                b.chain,
                <CopyToClipboard text={b.tokenAddress}>
                  {shortenAddress(b.tokenAddress)}
                </CopyToClipboard>,
                token.symbol,
                <span style={{ fontSize: 12, letterSpacing: 0.6 }}>
                  {(
                    Number(b.balance.toString()) /
                    10 ** token.decimals
                  ).toLocaleString()}
                </span>,
              ];
            })}
          loading={gasTankLoading}
          placeholderText="Sign in to view balances"
          showPlaceholder={!Boolean(stateAddress) || !Boolean(address)}
          copyValue={"token address"}
        />
      </div>
    );
  }, [backendBalances, gasTankLoading, stateAddress, address]);

  const TransactionHistory = useMemo(() => {
    return (
      <div className={css.tableContainer}>
        <Table
          columnLabels={["Txn hash | ID", "Type", "status"]}
          rows={transactionHistory.map((item) => {
            let rows = [];
            if (
              item.type == "increase" &&
              item.pendingTransactions.length > 0
            ) {
              item.pendingTransactions.forEach((pt) => {
                rows.push(
                  <span title={pt.transactionHash}>
                    <CopyToClipboard text={pt.transactionHash}>
                      {shortenAddress(pt.transactionHash)}
                    </CopyToClipboard>
                  </span>,
                  item.type,
                  pt.confirmed ? (
                    <span className={css.green}>Confirmed</span>
                  ) : (
                    <span className={css.yellow}>Pending</span>
                  ),
                );
              });
            } else {
              rows.push(
                <span
                  title={
                    item.processed
                      ? item?.processedMetadata?.txHash || item.id
                      : item.id
                  }
                >
                  <CopyToClipboard
                    text={
                      item.processed
                        ? item?.processedMetadata?.txHash || item.id
                        : item.id
                    }
                  >
                    {shortenAddress(
                      item.processed
                        ? item?.processedMetadata?.txHash || item.id
                        : item.id,
                    )}
                  </CopyToClipboard>
                </span>,
                item.type,
                item.processed ? (
                  item?.processedMetadata?.success ? (
                    <span className={css.green}>Confirmed</span>
                  ) : (
                    <span className={css.red}>Failed</span>
                  )
                ) : (
                  <span className={css.yellow}>Pending</span>
                ),
              );
            }
            return rows;
          })}
          loading={transactionHistoryLoading}
          placeholderText="Sign in to view transactions"
          showPlaceholder={!Boolean(stateAddress) || !Boolean(address)}
          copyValue="Trnx hash"
        />
      </div>
    );
  }, [transactionHistory, transactionHistoryLoading, stateAddress, address]);

  const refreshOnChainBalances = () => {
    setOnChainLoading(true);
    window.dispatchEvent(
      new CustomEvent("update-chain-balances", {
        detail: () => setOnChainLoading(false),
      }),
    );
  };

  const refreshGasTankBalances = () => {
    setGasTankLoading(true);
    window.dispatchEvent(
      new CustomEvent("update-gas-balances", {
        detail: () => setGasTankLoading(false),
      }),
    );
  };

  const refreshTransactionHistory = () => {
    setTransactionHistoryLoading(true);
    window.dispatchEvent(
      new CustomEvent("update-history", {
        detail: () => setTransactionHistoryLoading(false),
      }),
    );
  };

  useEffect(() => {
    if (queue.length > 0) {
      clearInterval(intervalRef.current);

      intervalRef.current = setInterval(async () => {
        Promise.all(
          queue.map((queueItem) => getBalanceQueueUpdate(queueItem.id)),
        )
          .then((results) => {
            results.forEach((res: any) => {
              const queueItem = queue?.find((item) => item.id === res.id);
              dispatch(incrementCount(res.id));
              if (res.processed) {
                dispatch(removeFromQueue(res.id));
                // display success modal
                popupManager.open(StatusModal, {
                  onClose: () => {},
                  json: JSON.stringify(res, null, 2),
                });

                return;
              }
              if (queueItem && queueItem.count >= 7) {
                // display fail modal
                dispatch(removeFromQueue(queueItem.id));
                popupManager.open(StatusModal, {
                  onClose: () => {},
                  json: JSON.stringify(res, null, 2),
                  isTimeout: true,
                });
              }
            });
          })
          .catch((error) => {
            console.error("Failed to get balance queue update:", error);

            clearInterval(intervalRef.current);
          });
      }, 5000);
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
  }, [queue.length]);

  return (
    <div>
      <Header />
      <div className={css.content}>
        <div className={css.tableLabel}>
          Deposit gas once, and let the Gas Tank take care of the rest.
          <br /> Our Gas Tank feature supports 30+ compatible chains.
          <br /> <br />
          1. Please sign-in with your Ethereum wallet to view your balances and
          transaction history.
          <br />
          2. Deposit some of your favorite tokens to your Gas Tank (USDC, XDEFI
          from EVM Networks)
          <br />
          3. Send gas to any address on any supported chain (EVM, Cosmos,
          Solana, Thorchain, NEAR, UTXOs etc.)
        </div>
        <br />
        <div className={css.tableSection}>
          {isDesktop ? (
            <>
              <div className={css.tableFlex}>
                <p className={css.tableLabel}>
                  <span>On-chain {chain?.name} Balances</span>
                  <button type="button" onClick={refreshOnChainBalances}>
                    <RefreshIcon />
                  </button>
                </p>
                <div className={`${css.tableLabel} ${css.tableLabelWide}`}>
                  <div className={css.titleFlex}>
                    <span>Gas tank Balances</span>
                    {Boolean(stateAddress) && (
                      <div className={css.tableActionButtons}>
                        <button
                          className={css.tableActionButton}
                          onClick={() => popupManager.open(DepositForm)}
                          type="button"
                        >
                          Deposit
                        </button>
                        <button
                          className={css.tableActionButton}
                          onClick={() => popupManager.open(TransferModal)}
                          type="button"
                        >
                          Transfer
                        </button>
                        <button
                          className={css.tableActionButton}
                          onClick={() => popupManager.open(ConsumeV2Form)}
                          type="button"
                        >
                          Consume/Withdraw
                        </button>
                      </div>
                    )}
                  </div>
                  <button type="button" onClick={refreshGasTankBalances}>
                    <RefreshIcon />
                  </button>
                </div>
                <p className={css.tableLabel}>
                  <span>Pending transactions</span>
                  <button type="button" onClick={refreshTransactionHistory}>
                    <RefreshIcon />
                  </button>
                </p>
              </div>

              <div className={css.tableFlex}>
                {OnChainBalances}
                {GasTankBalances}
                {TransactionHistory}
              </div>
            </>
          ) : (
            <div>
              <div className={css.tabTitleContainer}>
                <button
                  className={`${css.tabTitle} ${
                    currentTableTab === "on-chain" && css.selectedTab
                  }`}
                  onClick={() => setCurrentTableTab("on-chain")}
                >
                  On-Chain balances
                </button>
                <button
                  className={`${css.tabTitle} ${
                    currentTableTab === "gas-tank" && css.selectedTab
                  }`}
                  onClick={() => setCurrentTableTab("gas-tank")}
                >
                  Gas Tank balances
                </button>
                <button
                  className={`${css.tabTitle} ${
                    currentTableTab === "history" && css.selectedTab
                  }`}
                  onClick={() => setCurrentTableTab("history")}
                >
                  Transaction history
                </button>
              </div>
              {currentTableTab === "on-chain" && (
                <div>
                  <p className={css.tableLabel}>
                    <span>On-chain {chain?.name} Balances</span>
                    <button type="button">
                      <RefreshIcon />
                    </button>
                  </p>
                  {OnChainBalances}
                </div>
              )}
              {currentTableTab === "gas-tank" && (
                <div>
                  <p className={css.tableLabel}>
                    <div className={css.titleFlex}>
                      <span>Gas tank Balances</span>
                      {Boolean(stateAddress) && (
                        <div className={css.tableActionButtons}>
                          <button
                            className={css.tableActionButton}
                            onClick={() => popupManager.open(DepositForm)}
                            type="button"
                          >
                            Deposit
                          </button>
                          <button
                            className={css.tableActionButton}
                            onClick={() => popupManager.open(TransferModal)}
                            type="button"
                          >
                            Withdraw/Transfer
                          </button>
                        </div>
                      )}
                    </div>
                    <button type="button">
                      <RefreshIcon />
                    </button>
                  </p>
                  {GasTankBalances}
                </div>
              )}
              {currentTableTab === "history" && (
                <div>
                  <p className={css.tableLabel}>
                    <span>Transaction History</span>
                    <button type="button">
                      <RefreshIcon />
                    </button>
                  </p>
                  {TransactionHistory}
                </div>
              )}
            </div>
          )}
        </div>
        {Boolean(stateAddress) && <ConsumeForm />}
      </div>
    </div>
  );
};

export default Home;
