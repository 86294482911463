import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../features/authSlice";
import queueSlice from "../features/queueSlice";
import tokensSlice from "../features/tokensSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    queue: queueSlice.reducer,
    tokens: tokensSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
