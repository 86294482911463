import React from "react";
import css from "./Table.module.scss";

interface TableProps {
  columnLabels: string[];
  rows: React.ReactNode[][];
  loading?: boolean;
  placeholderText?: string;
  showPlaceholder?: boolean;
  copyValue?: string;
};

const Table: React.FC<TableProps> = ({ columnLabels, placeholderText, showPlaceholder = false, rows, loading = false, copyValue }) => {
  return (
    <div className={css.tableWrapper}>
      {
        showPlaceholder ? <div className={css.loading}>{placeholderText}</div> : (
          loading ? <div className={css.loading}>Loading...</div> : (
            <table className={css.table}>
              <thead>
                <tr className={css.th}>
                  {columnLabels.map((label, index) => <td key={`table-th-${index}-${label}`}>{label} {copyValue === label && <span className={css.copy}>Click to copy address</span>}</td>)}
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => <tr key={`table-tr-${index}`} className={css.tr}>{row.map((cell, i) => <td key={`table-td-${index}-${i}`}>{cell}</td>)}</tr>)}
              </tbody>
            </table>
          )
        )
      }
    </div>
  );
};

export default Table;