import React, { useState } from "react";
import WithdrawForm from "../Forms/WithdrawForm";
import InternalTransferForm from "../Forms/InternalTransferForm";
import InternalMigrateForm from "../Forms/InternalMigrateForm";
import Modal, { ModalProps } from "../Modal";
import css from "../Home/Home.module.scss";

const TransferModal: React.FC<ModalProps> = (props) => {
  const [tab, setTab] = useState<"withdraw" | "internal" | "migrate">(
    "withdraw",
  );
  return (
    <Modal {...props}>
      <div>
        <div className={css.tabTitleContainer}>
          <button
            className={`${css.tabTitle} ${tab === "withdraw" && css.selectedTab}`}
            onClick={() => setTab("withdraw")}
          >
            Withdraw[Deprecated]
          </button>
          <button
            className={`${css.tabTitle} ${tab === "internal" && css.selectedTab}`}
            onClick={() => setTab("internal")}
          >
            Internal Transfer
          </button>
          <button
            className={`${css.tabTitle} ${tab === "migrate" && css.selectedTab}`}
            onClick={() => setTab("migrate")}
          >
            Migration
          </button>
        </div>
        {tab === "withdraw" && <WithdrawForm onClose={props.onClose} />}
        {tab === "internal" && <InternalTransferForm onClose={props.onClose} />}
        {tab === "migrate" && <InternalMigrateForm onClose={props.onClose} />}
      </div>
    </Modal>
  );
};

export default TransferModal;
