// src/hooks/useBackendBalances.ts
import { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../api/backendApi";
import { LOGOUT_MESSAGES, LS_AUTH_KEY } from "../util/constants";
import { logout } from "../features/authSlice";
import { useAppDispatch } from "../app/hooks";

export interface HistoryItem {
  id: string;
  address: string;
  tokenAddress: string;
  amount: string;
  chain: string;
  type: string;
  processed: boolean;
  processedMetadata: {
    fee: string;
    txHash: string;
    success: boolean;
    newBalance: string;
    previousBalance: string;
  };
  pendingTransactions: {
    chain: string;
    transactionHash: string;
    blockNumber: number;
    balanceUpdateId: string;
    confirmed: boolean;
    createdAt: string;
    updatedAt: string;
  }[];
  createdAt: string;
  updatedAt: string;
}

export function useTransactionHistory(
  address: string | null,
  token: string | null,
  stateAddress: string | null | undefined,
) {
  const [history, setHistory] = useState<HistoryItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const AUTH_TOKEN = window.localStorage.getItem(LS_AUTH_KEY);
  const auth = AUTH_TOKEN && JSON.parse(AUTH_TOKEN);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchHistory = async (e?: any) => {
      if (!AUTH_TOKEN) {
        dispatch(logout());
        return;
      }

      if (auth.token) {
        setLoading(true);
        setError(null);
        try {
          const response = await axios.get(`${BASE_URL}/transactions`, {
            headers: { Authorization: `Bearer ${auth.token}` },
          });

          setHistory(
            (<HistoryItem[]>Object.values(response.data)[0]).slice(0, 5),
          );

          if (e) {
            e.detail();
          }
        } catch (error: any) {
          console.error("Failed to fetch backend balances:", error);
          setError(error.message);

          if (LOGOUT_MESSAGES.includes(error?.response?.data?.message)) {
            dispatch(logout());
          }
        } finally {
          setLoading(false);
        }
      } else {
        setHistory([]);
      }
    };

    fetchHistory();

    // const intervalId = setInterval(fetchHistory, 10000); // fetch every 10 seconds
    window.addEventListener("update-history", fetchHistory);

    return () => {
      // clearInterval(intervalId);
      window.removeEventListener("update-history", fetchHistory);
    };
  }, [address, token, stateAddress]);

  return { history, loading, error, setLoading };
}
