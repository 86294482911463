import React from "react";
import Modal, { ModalProps } from "../Modal";
import { SubmitButton } from "../Button";
import css from "./ConfirmSendGasModal.module.scss";
import { ChainKey, Token } from "../../constants";
import { useAppSelector } from "../../app/hooks";

interface ConfirmSendGasModalProps extends ModalProps {
  onSuccess?: (e: any) => any;
  chainId: number | string;
  amount: number;
  spentAssets?: {
    amount: string;
    chain: string;
    tokenAddress: string;
  }[];
  token?: string;
}

const ConfirmSendGasModal: React.FC<ConfirmSendGasModalProps> = ({
  spentAssets,
  chainId,
  amount,
  token,
  ...props
}) => {
  const { tokens } = useAppSelector((state) => state.tokens);

  return (
    <Modal {...props}>
      <div className={css.wrapper}>
        <h2 className={css.title}>Confirm the transaction</h2>
        It will spend from your balance...
        <br />
        {spentAssets &&
          spentAssets.map((asset) => {
            const token = tokens[asset.chain as ChainKey]?.tokens?.find(
              (token) => token.address === asset.tokenAddress,
            ) as Token;
            return (
              <p key={asset.tokenAddress}>
                {+asset.amount * 10 ** -token?.decimals} {token?.symbol}
              </p>
            );
          })}
        And you will receive {Number(amount)} {token}
        <div className={css.buttonContainer}>
          <button
            className={css.cancelButton}
            type="button"
            onClick={props.onClose}
          >
            Cancel
          </button>
          <SubmitButton
            onClick={(e) => {
              if (props.onSuccess) {
                props.onSuccess(e);
              }
              if (props.onClose) {
                props.onClose();
              }
            }}
          >
            Confirm
          </SubmitButton>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmSendGasModal;
