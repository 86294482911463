import React from "react";
import Modal, { ModalProps } from "../../Modal";
import css from "./Descriptions.module.scss";

const DepositDescription: React.FC<ModalProps> = (props) => {
  return (
    <Modal {...props}>
      <div className={css.content}>
        <h3>Deposit Form Description:</h3>
        <br />
        <p>This form allows users to deposit tokens into their account by specifying the desired token address, amount, and blockchain network (or "chain"). Here's a step-by-step guide to using the deposit form:</p>
        <br />
        <ol className={css.list}>
          <li><b>Token Address:</b> This is the address of the ERC-20 token you wish to deposit. It's crucial to ensure that the token address is correct and belongs to the respective blockchain network you choose next.</li>
          <li><b>Amount:</b> Input the quantity of tokens you want to deposit. Ensure you have enough balance in your wallet for the deposit and potential network fees.</li>
          <li><b>Chain:</b> Select the blockchain network where the token resides. Currently, Ethereum, Avalanche, and Arbitrum are supported. Please make sure the token you're depositing is from the selected blockchain.</li>
          <li>Click on the <b>Deposit</b> button to initiate the deposit. The system will ask you to sign a permit message (which allows the backend to move tokens on your behalf without requiring an ETH transaction), and then the deposit will be processed.</li>
        </ol>
        <br />
        <p>Please wait for a confirmation message after submitting your deposit request, and always double-check all details before confirming any transaction.</p>
      </div>
    </Modal>
  );
}

export default DepositDescription;