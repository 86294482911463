import React from 'react';


export default function useOutsideClick(ref: any, isOpen: boolean, callback: (event: any) => void) {
  React.useEffect(() => {
    function handleClickOutside(event: any) {
      const runCallback = () => !!callback && callback(event);
      if (ref.current && !ref.current.contains(event.target)) {
        runCallback();
      }
    }

    if (isOpen) document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref, callback, isOpen]);
}
