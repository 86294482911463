import React from "react";
import Modal, { ModalProps } from "../../Modal";
import css from "./Descriptions.module.scss";

const ConsumeV2Description: React.FC<ModalProps> = (props) => {
  return (
    <Modal {...props}>
      <div className={css.content}>
        <h3>Withdraw Token | Send Gas Form Description</h3>
        <br />
        <p>
          This form facilitates the consumption of your gas tank balance to get
          gas across chains or withdraw your internal balance to your desired
          account. Here are the steps on how to use it:
        </p>
        <ol className={css.list}>
          <li>
            <strong>Select:</strong> Choose which assets you want to spend to
            make consumptions. If you leave this field empty, it will be
            selected automatically.
          </li>
          <li>
            <strong>Min Destination Amount:</strong> Define the minimum amount
            you intend to receive on the target chain. This safeguards you
            against possible minor rate changes during the transaction.
          </li>
          <li>
            <strong>Destination Address:</strong> Input the address on the
            target chain where you wish the funds to be sent.
          </li>
          <li>
            <strong>Destination Chain:</strong> Specify the blockchain where you
            want the funds to be transferred.
          </li>
          <li>
            <strong>Select Token:</strong> Choose the token you wish to receive.
            If you leave this field empty, the native token based on the
            destination chain will be selected.
          </li>
        </ol>
        <p>
          Press the <strong>Consume</strong> button to initiate the transaction.
          Ensure to verify all details before executing the operation.
        </p>
        <p>
          <em>
            Always review all details thoroughly before confirming any
            transaction.
          </em>
        </p>
      </div>
    </Modal>
  );
};

export default ConsumeV2Description;
