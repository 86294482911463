import axios, { AxiosResponse } from "axios";
import {
  IncreaseMsgApiResponse,
  IncreaseMsgRequest,
  MigrationMsgRequest,
  SignMsgResponse,
  Tokens,
} from "./types";

// export const BASE_URL = "https://gas-tank.dev.xdefiservices.com";
export const BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3000";

const LS_AUTH_KEY = "jwt:auth";

// Request and Response Types
interface IncreaseBalanceRequest {
  address: string;
  tokenAddress: string;
  chain: string;
  owner: string;
  spender: string;
  value: string;
  deadline: number;
  v: number;
  r: string;
  s: string;
}

interface WithdrawBalanceRequest {
  address: string;
  tokenAddress: string;
  amount: string;
  chain: string;
  recipient: string;
  message: string;
  signature: string;
}

export interface ConsumeBalanceRequest {
  address: string;
  minDestinationAmount?: string;
  destinationAddress: string;
  destinationChain: string;
  message: string;
  signature: string;
}

interface Balance {
  id: string;
  address: string;
  tokenAddress: string;
  balance: number;
  chain: string;
  action: string;
  createdAt: string;
}

interface ConversionRates {
  [token: string]: {
    [denom: string]: number;
  };
}

interface BackendInfo {
  conversionRates: ConversionRates;
  spenderAddresses: string[];
}

export interface ApiResponse {
  id: string;
  type: string;
  address: string;
  tokenAddress: string;
  amount: string;
  chain: string | null;
  destinationChain: string | null;
  destinationAddress: string | null;
  permitObject: any;
  processed: boolean;
  processedMetadata: any;
  createdAt: Date;
  updatedAt: Date;
}

export interface Auth {
  access: string;
  refresh: string;
}

export interface AuthMessage {
  nonce: string;
  message: string;
}

export async function getAuthMessage(
  addresses: string[],
): Promise<AuthMessage> {
  try {
    const response = await axios.post(
      `${BASE_URL}/v2/auth/message`,
      addresses,
      { headers: { "Content-Type": "application/json" } },
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch auth message:", error);
    throw error;
  }
}

export async function login(address: any, signature: string): Promise<Auth> {
  try {
    const response: AxiosResponse<Auth> = await axios.post(
      `${BASE_URL}/v2/auth/login`,
      [{ address, signature }],
      { headers: { "Content-Type": "application/json" } },
    );
    return response.data;
  } catch (error) {
    console.error("Failed to login:", error);
    throw error;
  }
}

export async function getBackendInfo(): Promise<BackendInfo> {
  try {
    const response = await axios.get(`${BASE_URL}/balances/info`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch backend info:", error);
    throw error;
  }
}

export async function getBackendBalances(address: string): Promise<Balance[]> {
  const AUTH_TOKEN = window.localStorage.getItem(LS_AUTH_KEY);
  const auth = AUTH_TOKEN && JSON.parse(AUTH_TOKEN);
  try {
    const response = await axios.get(
      `${BASE_URL}/balances/balances/${address}`,
      { headers: { Authorization: `Bearer ${auth.token}` } },
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch backend balances:", error);
    throw error;
  }
}

export async function increaseBalanceMsg(
  data: IncreaseMsgRequest,
): Promise<IncreaseMsgApiResponse> {
  try {
    const response: AxiosResponse<IncreaseMsgApiResponse> = await axios.post(
      `${BASE_URL}/msg/increase`,
      data,
    );
    return response.data;
  } catch (error) {
    console.error("Failed to generate increase msg:", error);
    throw error;
  }
}

export async function migrationBalanceMsg(
  data: MigrationMsgRequest,
): Promise<SignMsgResponse> {
  try {
    const response: AxiosResponse<SignMsgResponse> = await axios.post(
      `${BASE_URL}/msg/internal-migration`,
      data,
    );
    return response.data;
  } catch (error) {
    console.error("Failed to generate internal migration msg:", error);
    throw error;
  }
}

export async function increaseBalance(
  data: IncreaseBalanceRequest,
): Promise<ApiResponse> {
  try {
    const response: AxiosResponse<ApiResponse> = await axios.post(
      `${BASE_URL}/balances/increase`,
      data,
    );
    return response.data;
  } catch (error) {
    console.error("Failed to increase backend balance:", error);
    throw error;
  }
}

export async function consumeBalance(
  data: ConsumeBalanceRequest,
): Promise<ApiResponse> {
  try {
    const response: AxiosResponse<ApiResponse> = await axios.post(
      `${BASE_URL}/balances/consume`,
      data,
    );
    return response.data;
  } catch (error) {
    console.error("Failed to consume backend balance:", error);
    throw error;
  }
}

export async function withdrawBalance(
  data: WithdrawBalanceRequest,
): Promise<ApiResponse> {
  try {
    const response: AxiosResponse<ApiResponse> = await axios.post(
      `${BASE_URL}/balances/withdraw`,
      data,
    );
    return response.data;
  } catch (error) {
    console.error("Failed to withdraw backend balance:", error);
    throw error;
  }
}

interface InternalTransferRequest {
  address: string;
  tokenAddress: string;
  amount: string;
  chain: string;
  recipient: string;
  message: string;
  signature: string;
}

interface InternalMigrationRequest {
  address: string;
  recipient: string;
  message: string;
  signature: string;
}

export async function internalTransfer(
  data: InternalTransferRequest,
): Promise<ApiResponse> {
  try {
    const response: AxiosResponse<ApiResponse> = await axios.post(
      `${BASE_URL}/balances/internal_transfer`,
      data,
    );
    return response.data;
  } catch (error) {
    console.error("Failed to make internal transfer:", error);
    throw error;
  }
}

export async function internalMigration(
  data: InternalMigrationRequest,
): Promise<ApiResponse> {
  try {
    const response: AxiosResponse<ApiResponse> = await axios.post(
      `${BASE_URL}/balances/internal-migration`,
      data,
    );
    return response.data;
  } catch (error) {
    console.error("Failed to make internal migration:", error);
    throw error;
  }
}

export interface QuoteBalanceRequest {
  address: string;
  minDestinationAmount: string;
  destinationChain: string;
}

export async function quoteBalance(data: QuoteBalanceRequest): Promise<any> {
  try {
    const response: AxiosResponse<ApiResponse> = await axios.post(
      `${BASE_URL}/balances/quote`,
      data,
    );
    return response.data;
  } catch (error) {
    console.error("Failed to get quote:", error);
    throw error;
  }
}

export async function getBalanceQueueUpdate(id: string): Promise<any> {
  const AUTH_TOKEN = window.localStorage.getItem(LS_AUTH_KEY);
  const auth = AUTH_TOKEN && JSON.parse(AUTH_TOKEN);

  try {
    const response: AxiosResponse<ApiResponse> = await axios.get(
      `${BASE_URL}/transactions/${id}`,
      { headers: { Authorization: `Bearer ${auth.token}` } },
    );
    return response.data;
  } catch (error) {
    console.error("Failed to get balance queue update:", error);
    throw error;
  }
}

export async function getAllowedTokens(jwtToken: string): Promise<Tokens> {
  try {
    const response = await axios.get(`${BASE_URL}/chains/tokens`, {
      headers: { Authorization: `Bearer ${jwtToken}` },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to get allowed tokens:", error);
    throw error;
  }
}

export async function getPositiveBalances(addresses: string[]) {
  try {
    const response = await axios.post(`${BASE_URL}/balances/has-positive`, {
      addresses,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
