import React from "react";
import css from "./Modal.module.scss";
import { CloseIcon } from "../../assets";

export interface ModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
};

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <>
      <div className={css.backdrop} onClick={onClose}></div>
      <div className={css.wrapper}>
        <button type="button" onClick={onClose} className={css.closeIcon}>
          <CloseIcon color="white" />
        </button>
        <div className={css.content}>
          {children}
        </div>
      </div>
    </>
  );
}

export default Modal;