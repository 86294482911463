import React from "react";
import css from "./Header.module.scss";
import { LogoDesktop, LogoMobile } from "../../assets";
import ConnectWallet from "../ConnectWallet";
import { useBreakpoint } from "../../hooks/useBreakpoints";

const Header: React.FC = () => {
  const { isMobile } = useBreakpoint();

  return (
    <header className={css.wrapper}>
      {
        isMobile ? (
          <LogoMobile />
        ) : (
          <LogoDesktop />
        )
      }
      <ConnectWallet />
    </header>
  );
};

export default Header;
