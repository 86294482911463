import React, { useMemo, useState } from "react";
import { withdrawBalance } from "../../api/backendApi";
import { useAccount, useSignMessage } from "wagmi";
import { AxiosError } from "axios";
import css from "./Forms.module.scss";
import Input from "../Input";
import Select from "../Select";
import { SubmitButton } from "../Button";
import { Token } from "../../constants";
import { notification } from "antd";
import { ModalProps } from "../Modal";
import { InfoIcon } from "../../assets";
import { usePopupManager } from "react-popup-manager";
import { WithdrawDescription } from "./Descriptions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addToQueue } from "../../features/queueSlice";
import { useBackendBalances } from "../../hooks/useBackendBalances";
import { BigNumber } from "ethers";

const WithdrawForm: React.FC<ModalProps> = ({ onClose }) => {
  const { address } = useAccount();
  const [token, setToken] = useState<Token>({} as Token);
  const [amount, setAmount] = useState("");
  const [recipient, setRecipient] = useState(""); // new state variable for recipient
  const [loading, setLoading] = useState(false);
  const [chain, setChain] = useState<number>(1); // new state variable for chain selection
  const [error, setError] = useState("");

  const popupManager = usePopupManager();

  const dispatch = useAppDispatch();
  const { address: stateAddress } = useAppSelector((state) => state.auth);

  const { tokens } = useAppSelector((state) => state.tokens);

  const chainInfo = useMemo(
    () => Object.values(tokens).find((token) => token.chainId == chain),
    [tokens, chain],
  );

  const tokenOptions = useMemo(
    () =>
      Object.values(tokens)
        .find((token) => token.chainId === chain)
        ?.tokens.map((token) => ({
          label: token.symbol,
          value: { ...token, key: token.address },
        })) || [],
    [chain, tokens],
  );

  const chainOptions = useMemo(
    () =>
      Object.values(tokens)
        .filter((c) => c.tokens.some((t) => t.actions.includes("withdraw")))
        .map((info) => ({
          label: info.name,
          value: info.chainId,
        })),
    [tokens],
  );

  const { balances, loading: balanceLoading } = useBackendBalances(
    address || null,
    null,
    stateAddress,
  );

  const { signMessageAsync } = useSignMessage();

  const handleTokenChange = (value: Token) => {
    setToken(value);
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    const regex = /^[0-9]*\.?[0-9]*$/;

    if (regex.test(inputValue)) {
      setAmount(inputValue);
      setError("");
    } else {
      if (inputValue.includes("-")) {
        setError("Invalid input. Negative numbers are not allowed");
      } else if (inputValue.includes(",")) {
        setError("Invalid input. Use . for decimal point");
      } else {
        setError("Invalid input. Only [0-9] and . are allowed");
      }
    }
  };

  // handler for recipient field change
  const handleRecipientChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRecipient(e.target.value);
  };

  // handler for chain selection change
  const handleChainChange = (value: string) => {
    setChain(parseInt(value));
    setToken({} as Token);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const amountStr = BigNumber.from(+amount)
        .mul(10 ** token.decimals)
        .toLocaleString()
        .replace(/,/g, "");
      console.log(amountStr);
      const timestamp = Date.now();
      const message = `Withdraw request for ${address} and ${token.address} with amount ${amountStr} at ${timestamp} recipient=${recipient} chain=${chainInfo?.key}`;
      const signature = await signMessageAsync({
        message,
      });
      // Call the withdraw function from your backendApi here
      // Replace this with the appropriate parameters for your demo
      if (chainInfo) {
        const resp = await withdrawBalance({
          address: address!,
          tokenAddress: token.address,
          amount: amountStr,
          chain: chainInfo.key,
          recipient,
          message,
          signature,
        });
        if (resp?.id) {
          dispatch(addToQueue(resp.id));
        }
        console.log("Withdraw successful", resp);

        notification.success({ message: "Success!" });
        if (onClose) {
          onClose();
        }
      }
    } catch (error: any) {
      console.error("Withdraw failed:", error);

      if (error instanceof AxiosError) {
        return notification.error({ message: error?.response?.data.message });
      }

      notification.error({ message: "Something went wrong." });
    } finally {
      setLoading(false);
    }
  };

  const showDescription = () => {
    popupManager.open(WithdrawDescription);
  };

  const availableBalance = React.useMemo(() => {
    const balance = Number(
      balances?.find((b) => b.tokenAddress === token.address)?.balance,
    );
    if (balance) {
      return balance / 10 ** token.decimals;
    }
    return null;
  }, [balances, balanceLoading, token]);

  return (
    <div className={css.formWrapper}>
      <h2 className={css.title}>
        Withdraw{" "}
        <button type="button" onClick={showDescription}>
          <InfoIcon />
        </button>
      </h2>
      <form onSubmit={handleSubmit} className={css.form}>
        <div className={`${css.twoCol} ${css.mb12}`}>
          <Select
            onChange={handleChainChange}
            value={chain}
            placeholder="Select Chain"
            options={chainOptions}
          />
          <Select
            onChange={handleTokenChange}
            value={token}
            placeholder="Select Token"
            options={tokenOptions}
          />
        </div>
        <Input
          value={amount}
          onChange={handleAmountChange}
          placeholder="Enter amount"
          suffix={token.symbol}
          className={css.mb12}
        />
        <Input
          value={recipient}
          onChange={handleRecipientChange}
          placeholder="Recepient"
          className={css.mb12}
        />
        {balanceLoading && (
          <p className={`${css.mb12} ${css.subtext}`}>Loading...</p>
        )}

        {!balanceLoading &&
          (availableBalance ? (
            <p className={`${css.mb12} ${css.subtext}`}>
              Available {availableBalance} {token.symbol}
            </p>
          ) : (
            <p className={`${css.mb12} ${css.subtext}`}>Insufficient balance</p>
          ))}
        {error && <p className={`${css.errorMessage} ${css.mb12}`}>{error}</p>}
        <div className={css.submitButtonContainer}>
          <SubmitButton
            disabled={
              loading || !amount || !recipient || !token.address || !chain
            }
          >
            {loading ? "Loading..." : "Withdraw"}
          </SubmitButton>
        </div>
      </form>
    </div>
  );
};

export default WithdrawForm;
