// src/hooks/useEthereumBalances.ts
import { useState, useEffect } from "react";
import { ethers, providers } from "ethers";
import { erc20ABI } from "wagmi";

export interface Balances {
  ethBalance: ethers.BigNumberish | null;
  tokenBalances: { [tokenAddress: string]: ethers.BigNumberish };
}

export function useEthereumBalances(
  provider: providers.JsonRpcProvider | null,
  address: string | null,
  tokenAddresses: string[],
) {
  const [balances, setBalances] = useState<Balances>({
    ethBalance: null,
    tokenBalances: {},
  });

  useEffect(() => {
    const fetchBalances = async (e?: any) => {
      if (provider && address) {
        try {
          const ethBalance = await provider.getBalance(address);
          const tokenBalances: {
            [tokenAddress: string]: ethers.BigNumberish;
          } = {};

          for (const tokenAddress of tokenAddresses) {
            const tokenContract = new ethers.Contract(
              tokenAddress,
              erc20ABI,
              provider,
            );
            const tokenBalance = await tokenContract.balanceOf(address);
            tokenBalances[tokenAddress] = tokenBalance;
          }

          setBalances({ ethBalance, tokenBalances });
          if (e) {
            e.detail();
          }
        } catch (error) {
          console.error("Failed to fetch Ethereum balances:", error);
        }
      } else {
        setBalances({ ethBalance: null, tokenBalances: {} });
      }
    };

    fetchBalances();

    const intervalId = setInterval(fetchBalances, 10000); // fetch every 10 seconds

    window.addEventListener("update-chain-balances", fetchBalances);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("update-chain-balances", fetchBalances);
    };
  }, [provider, address, tokenAddresses.length]);

  return balances;
}
