import React, { useMemo, useRef, useState } from "react";
import css from "./Select.module.scss";
import useOutsideClick from "../../hooks/useOutsideClick";
import { ArrowDownIcon } from "../../assets";

interface SelectProps {
  options: { label: React.ReactNode; value: any }[];
  placeholder?: string;
  value?: any;
  onChange: (value: any) => void;
  className?: string;
  disabled?: boolean;
};

const Select: React.FC<SelectProps> = ({ onChange, options, placeholder, value, className, disabled = false }) => {
  const currentSelected = useMemo(() => options.find(option => JSON.stringify(option.value) === JSON.stringify(value))?.label || undefined, [value, options]);
  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useRef(null);

  useOutsideClick(wrapperRef, isOpen, () => setIsOpen(false));

  return (
    <div className={`${css.wrapper} ${className}`} onClick={!disabled ? (() => setIsOpen(!isOpen)) : undefined} ref={wrapperRef}>
      {!Boolean(currentSelected) ? <span className={css.placeholder}>{placeholder}</span> : currentSelected}
      <button type="button"><ArrowDownIcon className={isOpen ? css.arrowIconOpen : undefined} /></button>
      {
        isOpen && (
          <div className={css.optionContainer}>
            {options.filter(opt => opt.value !== value).map(option => {
              return <span className={css.option} key={typeof option.value === 'object' ? option.value.key : option.value} onClick={() => {setIsOpen(false); onChange(option.value)}}>{option.label}</span>
            })}
          </div>
        )
      }
    </div>
  );
};

export default Select;