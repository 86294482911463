import React from "react";
import Modal, { ModalProps } from "../../Modal";
import css from "./Descriptions.module.scss";

const SendGasDescription: React.FC<ModalProps> = (props) => {
  return (
    <Modal {...props}>
      <div className={css.content}>
        <h3>Send Gas Form Description:</h3>
        <br />
        <p>This form facilitates the consumption of your gas tank balance to get gas across chains. Here are steps on how to use it:</p>
        <br />
        <ol className={css.list}>
          <li><b>Min Destination Amount:</b> Define the minimum amount you intend to receive on the target chain. This safeguards you against possible minor rate changes during the transaction.</li>
          <li><b>Destination Address:</b> Input the address on the target chain where you wish the funds to be sent.</li>
          <li><b>Destination Chain:</b> Specify the blockchain where you want the funds to be transferred. It will send the gas token.</li>
          <li>Press the <b>Send Gas</b> button to initiate the transaction. Ensure to verify all details before executing the operation.</li>
        </ol>
        <br />
        <p>Always review all details thoroughly before confirming any transaction.</p>
      </div>
    </Modal>
  );
}

export default SendGasDescription;