import React from "react";
import Modal, { ModalProps } from "../../Modal";
import css from "./Descriptions.module.scss";

const InternalDescription: React.FC<ModalProps> = (props) => {
  return (
    <Modal {...props}>
      <div className={css.content}>
        <h3>Internal Transfer Form Description:</h3>
        <br />
        <p>This form enables users to transfer tokens internally within the platform. Follow these steps for a successful transfer:</p>
        <br />
        <ol className={css.list}>
          <li><b>Token Address:</b> Input the ERC-20 token address you intend to transfer. Ensure this token is supported on the selected blockchain.</li>
          <li><b>Amount:</b> Specify the quantity of tokens you wish to transfer. Verify you possess the necessary balance.</li>
          <li><b>Destination Address:</b> Insert the platform address set to receive the transferred tokens. Always double-check addresses for correctness. This should not be a token contract address!</li>
          <li><b>Chain:</b> Select the desired blockchain for the transfer. Ascertain the chosen network is compatible with the indicated token.</li>
          <li>Hit the <b>Transfer</b> button to initialize the operation. Authentication might be required, necessitating a message signature to affirm transfer specifics. After confirmation, the transaction proceeds.</li>
        </ol>
        <br />
        <p>Always peruse all details thoroughly before confirming any transaction.</p>
      </div>
    </Modal>
  );
}

export default InternalDescription;