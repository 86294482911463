// src/App.tsx
import React from "react";
import Home from "./Home";

import { WagmiConfig, createClient, configureChains, mainnet } from "wagmi";
import {
  arbitrum,
  avalanche,
  fantom,
  polygon,
  optimism,
  sepolia,
} from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { PopupProvider } from "react-popup-manager";

// const evmProviderUrls: Record<string, string> = {
//   1: "https://eth.llamarpc.com", // Replace with your Ethereum provider
//   43114: "https://rpc-proxy.xdefi.services/avalanche/mainnet", // Replace with your Avalanche provider
//   250: "https://rpc-proxy.xdefi.services/fantom/mainnet", // Replace with your Fantom provider
//   137: "https://rpc-proxy.xdefi.services/polygon/mainnet", // Replace with your Polygon provider
//   56: "https://rpc-proxy.xdefi.services/bsc/mainnet", // Replace with your Binance Smart Chain provider
//   42161: "https://rpc-proxy.xdefi.services/arbitrum/mainnet", // Replace with your Arbitrum provider
// };

const App: React.FC = () => {
  const {
    chains: chainsRK,
    provider,
    webSocketProvider,
  } = configureChains(
    [mainnet, avalanche, polygon, fantom, arbitrum, optimism, sepolia], // not bsc
    [
      publicProvider(),
      // jsonRpcProvider({
      //   rpc: (chain) => {
      //     console.debug("Rpc", chain.id);
      //     const url = evmProviderUrls[chain.id];
      //     return {
      //       http: url,
      //     };
      //   },
      // }),
      // publicProvider(),
    ],
  );
  console.debug({ chainsRK });
  const client = createClient({
    autoConnect: true,
    provider,
    webSocketProvider,
  });

  return (
    <WagmiConfig client={client}>
      <PopupProvider>
        <div>
          <Home />
        </div>
      </PopupProvider>
    </WagmiConfig>
  );
};

export default App;
