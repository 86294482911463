import React from "react";
import css from "./Button.module.scss";

interface ButtonProps {
  disabled?: boolean;
  label?: string;
  children?: React.ReactNode;
  onClick?: (e?: any) => void;
  className?: string;
};

const Button: React.FC<ButtonProps> = ({ label, children, disabled, onClick, ...props }) => {
  return (
    <button
      {...props}
      type="button"
      className={`${props.className ? props.className : css.button}`}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
      {children}
    </button>
  );
};

export const SubmitButton: React.FC<ButtonProps> = ({ label, children, disabled, onClick, ...props }) => {
  return (
    <button
      type="submit"
      className={css.submitButton}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {label}
      {children}
    </button>
  );
};

export default Button;